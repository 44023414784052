import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const ImageList = styled.ul`
`;

const ImageListItem = styled.li`
`;


export default function ArtArchiveLayout({pageContext}) {
  
  const items = [...pageContext.art]
    .sort((a,b) => a.sortKey.localeCompare(b.sortKey))
    .reverse()
    .map(item => {
      return <ImageListItem>
        <Link to={item.path}>{item.title}</Link>
      </ImageListItem>
    });

  return (
    <div>
      <ImageList>
        {items}
      </ImageList>

        <footer>
          &copy; J Parkin {new Date().getFullYear()}.
        </footer>
    </div>
  )
}
